import React from 'react'
import Contact1 from '../ContactSec1/Contact1'
import Contact2 from '../ContactSec2/Contact2'

const Contact = () => {
  return (
    <div>
       <Contact1/>
        <Contact2/>
    </div>
  )
}

export default Contact