import React from 'react'
import Footer1 from './FooterSec/Footer1'
import Footer2 from './FooterSec/Footer2'

const Footer = () => {
  return (
    <div className='child '> 
      <Footer1/>
      <Footer2/>
    </div>
  )
}

export default Footer