import React from 'react'
import { Why1 } from '../WhySec1/Why1'
import Why2 from '../WhySec2/Why2'
import Why3 from '../WhySec3/Why3'
import Why12 from '../WhySec1/Why12'

const Why = () => {
  return (
    <div>
        {/* <Why1/> */}
        <Why12/>
        <Why2/>
        <Why3/>
    </div>
  )
}

export default Why