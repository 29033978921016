import React from 'react'
import { About1 } from '../AboutSec1/About1'
import { About2 } from '../AboutSec2/About2'
import { About3 } from '../AboutSec3/About3'
import AboutContact from '../AboutSec4/AboutContact'
import Contact2 from '../../Contact/ContactSec2/Contact2'

const About = () => {
  return (
    <div>
        <About1/>
<About2/>
<About3/>
<Contact2/>
{/* <AboutContact/> */}
    </div>
  )
}

export default About;