import React from 'react';
import bg from '../../../images/sec 7/bg.png';
import character from '../../../images/sec 7/character.png';
import './Sec7.css';
const Sec7 = () => {
  return (
    <div className='Sec-7 bg-cover bg-center h-screen lg:p-6 pb-0 relative child' style={{ backgroundImage: `url(${bg})` }}>
      <div className='flex items-center justify-center text-center'>
        <h1 className='Sec7-head text-white text-4xl m-12 mb-6 capitalize font-black font-lato text-center'>Why Choose  us?</h1>
      </div>
     
      <div className='Sec7-content grid grid-cols-1 md:grid-cols-2 gap-0'>
        <div className='Content1 flex items-center justify-center lg:p-20 pt-0'>
          <div className=' ml-3 lg:m-0 '>
            <h1 className='Cont1-h text-white text-2xl pb-3 font-black font-lato'>Comprehensive Services</h1>
            <p className='Cont1-p lg:mr-32 mr-24 text-white text-lg font-500 font-lato leading-6'>
            We provide cutting-edge technological experiences that go above and beyond your expectations 
            by utilizing the newest technology and techniques. Our comprehensive services are unmatched
             because we consistently develop new features and visually captivating designs.
            </p>
            <h1 className=' Cont1-h text-white text-2xl font-black font-lato mt-6 pb-3'>Customer-Centric Approach</h1>
            <p className=' Cont1-p lg:mr-32 mr-24 text-white text-lg font-500 font-lato leading-6'>
            At AIRAI, our top priority is our clients' satisfaction.
Throughout the development process, we collaborate closely with you to make sure your
goals are precisely and superbly achieved and your vision is realized.
            </p>
          </div>
        </div>
        <img src={character} alt="" className='Char-pic absolute bottom-0 left-1/2 transform -translate-x-1/2 object-contain  sm:text-left' />
        <div className='Sec7-content2 flex  items-end justify-end p-20 pb-0 h-auto absolute bottom-10 left-1/2 transform translate-x-0 text-right '>
          <div className='Content2  mr-16 '>
            <h1 className=' Cont2-h text-white text-2xl font-black font-lato pb-3 md:text-xl'>Innovative Solutions</h1>
            <p className='Cont2-p text-white text-lg lg:ml-24 font-500 font-lato leading-6'>
            we excel at turning ideas into powerful web solutions for brand growth. We lead the charge in technology
             and industry trends, always innovating to deliver cutting-edge solutions. Our dedication to creativity and innovation 
             ensures you receive advanced, effective products and services.
            </p>
            <h1 className='  Cont2-h text-white text-2xl font-black font-lato mt-6 pb-3 '>Proven Results, Reliability and Trust</h1>
            <p className='Cont2-p m-0  mb-0 lg:ml-28 text-white text-lg font-500 font-lato leading-6 ' >
            Working with Airai, as a partner means
            directing your ideas toward significant results.
            We value your time and prioritize timely delivery without compromising on quality throughout the 
            development process, which has proven your trust in us.        </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sec7;
